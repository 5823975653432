/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import * as React from 'react';
import { MetaData } from '../meta-data';
import Footer from '../../components/footer';
import {
  env,
  GOALS,
  MAX_PRICE_PROPERTY_LIMIT,
  MAX_PROPERTIES_PER_LIST_VIEW_PAGE,
  CHANGE_DEFAULT_SORT_WHEN_USING_POINTS,
  VARIANTS,
  BIGGER_MAP_CTA,
} from '../../constants';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import debounce from 'lodash/debounce';
import { Filters, HotelDetails } from '../dynamic-components';
import { getSeoSchema } from '../../utils/seo-schema-utils';
import { PlacesSearch } from '../places-search';
import {
  useMetrics,
  useWrappedRouter,
  useGetDreamPageData,
  useGetFilteredHotels,
  useFeatureToggleConfig,
  useGetMVTSelections,
} from '../../hooks';
import { Spinner } from '@dx-ui/osc-spinner';
import { CurrencyConverter } from '../currency-converter/currency-converter';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useInitializeConductrics } from '../../hooks/use-initialize-conductrics';
import { SortByDropDown } from '../sort-by-drop-down';
import { SearchHeader } from '../search-header';
import { HotelCounter } from '../hotel-counter';
import { useLocation } from '@dx-ui/framework-location-provider';
import useHotelSummaryOptions from '../../hooks/use-hotel-summary-options/use-hotel-summary-options';
import { hashHotelSummaryOptionsData } from '../../hooks/use-hotel-summary-options/utils/get-hashed-hotel-summary-options-data-from-cache';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import type { ActiveFiltersState } from '../filters/filter.constants';
import { initializeActiveFiltersState } from '../filters/filter-utils';
import { getSortType, SORTBY_DROP_DOWN } from '../../utils';
import { AboutLocationsPage } from '../about-locations/about-locations-page';
import { UsePoints } from '../use-points/use-points';
import { Interlinks } from '../interlinks';
import { LocationsMapMarkers } from '../map/map.markers.locations';
import { Map } from '../../components/map/map';
import type { LocationPageInterlink } from '../../gql/types';
import { sendReward, useConductricsSelection } from '@dx-ui/framework-conductrics';
import { SuggestedFilterButtons } from '../filters/suggested-filters';
import { ListView } from '../list-view/list-view';
import isEqual from 'lodash/isEqual';
import { Breadcrumbs } from '../breadcrumbs';
import { ZoomInForPricing } from '../zoom-in-for-pricing/zoom-in-for-pricing';
import { useGetDreamPageAttributes } from '../../hooks/use-get-dream-page-attributes';
import { useSortHotels } from '../../hooks/use-hotel-sort';
import { applyFiltersToHotel } from '../../providers/app-provider/app-provider.utils';
import { useCoordsFromParams } from '../../hooks/use-coords-from-params';
import { LogInBanner } from '../list-view/log-in-banner/log-in-banner';
import { useRenovationChipMVT } from '../../hooks/use-renovation-chip-mvt';
import { LocationsPageHeadingLevel1 } from './locations-page-heading';
import { useMediaQuery, useResizeObserver } from 'usehooks-ts';
import { MapListToggleButton } from '../map-list-toggle-button/map-list-toggle-button';
import { useRef } from 'react';
import { CompareHotelsToggle } from '../compare-hotels/compare-toggle';
import { CompareBar } from '../compare-hotels/compare-bar';
import { useFilterState } from '../../providers/filter-provider';
import { useGetMVTPointsFiltersTest } from '../filters/hook/use-get-max-points-mvt';

type LocationsPageProps = {
  initialBounds: google.maps.LatLngBoundsLiteral;
  path: string;
};

export const LocationsPage = ({ initialBounds: initBounds, path }: LocationsPageProps) => {
  const {
    selectedCtyhocn,
    selectedCurrency,
    paginationIndex,
    isListVisible,
    hotelsInBounds,
    sortType,
    shouldUsePoints,
    pageType,
  } = useAppState();
  const activeFiltersState = useFilterState();

  const dispatch = useAppDispatch();

  const isSlimView = useMediaQuery('(max-width: 767px)');

  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const { shouldUseParamCoords } = useCoordsFromParams();

  const [locationQueryPath, setLocationQueryPath] = React.useState(path);
  const { t } = useTranslation([
    'hotel-card',
    'locations-page',
    'sort-by-drop-down',
    'footer',
    'hotel-counter-text',
  ]);
  const metrics = useMetrics();
  const { guestInfo, isLoading: isAuthLoading, isAuthenticated } = useAuth();

  const {
    data,
    locationPath,
    bounds: initialBounds,
    isLoading: isLocationPageHSOLoading,
  } = useGetDreamPageData();

  const { enabled: blobPocToggleEnabled } = useFeatureToggleConfig('NHCSEARCH-4655-blob-poc');

  const { pageAttributes } = useGetDreamPageAttributes(data?.location);

  const { categoryId, categoryName, isGlobalPage, isCategoryAmenity } = pageAttributes;
  const brandName =
    data?.hotelSummaryOptions?.brands?.find((brand) => brand.code === data?.location?.brandCode)
      ?.name || '';
  const brandCode = data?.location?.brandCode || '';
  const totalSize = data?.hotelSummaryOptions?._hotels?.totalSize || 0;
  const initialHashedHotelData = hashHotelSummaryOptionsData(data, {});
  const [mapBounds, setMapBounds] = React.useState<google.maps.LatLngBoundsLiteral>(initBounds);

  const isInitialRender = React.useRef(true);
  const allQuadNodesLoaded = React.useRef(true);
  const boundsChangeCount = React.useRef(0);

  const isCountryOrState = ['country', 'state', 'region'].includes(data?.match?.type || '');
  const { hashedHotelSummaryOptionsHotelData, isFetching: isHotelSummaryOptionsLoading } =
    useHotelSummaryOptions({
      initialHashedHotelSummaryOptionsData: initialHashedHotelData,
      bounds: mapBounds,
      brandCode: pageType.isPageBrandFilterEnabled ? brandCode : undefined,
      language: 'en',
      address: boundsChangeCount.current < 2 && isCountryOrState ? data?.match?.address : undefined,
      type: data?.match?.type,
      isInitialRender,
      matchName: data?.match?.name || '',
      blobEnabled: blobPocToggleEnabled,
    });

  const { coordinate } = useLocation();
  //NHCSEARCH-5310 Points Filter MVT
  const { isMaxPointsFilterTestLoaded } = useGetMVTPointsFiltersTest();

  //NHCSEARCH-5020 Change Default Sort When Using Points
  const { isMVTVariant, isLoaded: isPointSortMVTLoaded } = useGetMVTSelections({
    agentId: CHANGE_DEFAULT_SORT_WHEN_USING_POINTS?.agentId,
    isProvisional: shouldUsePoints || queryParameters?.redeemPts,
  });
  const isPointsSortMVT = isPointSortMVTLoaded && isMVTVariant?.[VARIANTS.VariantB];
  const initWithPointsSort = isPointsSortMVT && queryParameters?.redeemPts;

  React.useEffect(() => {
    if (router.isReady && queryParameters && allQuadNodesLoaded.current) {
      const locationCategory = isCategoryAmenity ? categoryId : undefined;
      const newActiveFilterState = initializeActiveFiltersState({
        category: locationCategory,
        queryParameters,
        brandCode,
        isMvtVariant: isMaxPointsFilterTestLoaded,
      });
      newActiveFilterState.showAvailableHotels = false;

      dispatch({
        type: 'INITIALIZE_APP',
        payload: {
          boundingBox: initialBounds || initBounds,
          initialHashedHotelSummaryOptionsData: initialHashedHotelData,
          usePoints: !!queryParameters?.redeemPts,
          hasConnectingRooms: !!queryParameters?.adjoiningRoomStay,
          sortType: initWithPointsSort
            ? SORTBY_DROP_DOWN.POINTS_ASCENDING
            : getSortType(brandCode, queryParameters?.sortBy),
          selectedCurrency: queryParameters?.displayCurrency || '',
          activeFiltersState: newActiveFilterState,
          useLeadPrice: true,
          brandCode,
        },
      });
      boundsChangeCount.current = 0;
      sendReward(GOALS.LocationsPageLoad);
    }
  }, [router.isReady, allQuadNodesLoaded.current, isCategoryAmenity]);

  // conductrics init
  useInitializeConductrics({ authLoading: isAuthLoading, guestInfo, isAuthenticated });

  const { isLoaded: isConductricsLoaded } = useConductricsSelection('');

  React.useEffect(() => {
    if (!isAuthLoading) {
      metrics.setDreamPageData({
        pageTitle: data?.location?.meta?.pageTitle || document.title,
        brandName: brandName || '',
        brandCode: brandCode || '',
        categoryName: categoryName || '',
        location: {
          type: data?.match?.type,
          typeName: data?.match?.name,
        },
        city: data?.match?.address?.city || '',
        country: data?.match?.address?.country || '',
        state: data?.match?.address?.state || '',
        honorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
        tier: guestInfo?.hhonors?.summary?.tierName || '',
        points: guestInfo?.hhonors?.summary?.totalPoints || 0,
        packages: (guestInfo?.hhonors?.packages as unknown as { packageName: string }[]) || [],
        currencyCode: selectedCurrency || '',
        shouldUsePoints,
        sortBy: sortType || SORTBY_DROP_DOWN.DISTANCE,
        totalSize,
      });
      metrics.setPageData({ page: 'categoryPageView' });
    }
  }, [isAuthLoading, categoryName, brandName]);

  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      allQuadNodesLoaded.current = true;
      boundsChangeCount.current = 0;
    });
  }, [router.events]);

  React.useEffect(() => {
    if (locationPath !== locationQueryPath) {
      setLocationQueryPath(locationPath);
    }
    // update with new bounds for shallow routing
    if (initialBounds && JSON.stringify(mapBounds) !== JSON.stringify(initialBounds))
      setMapBounds(initialBounds);
  }, [locationPath, initialBounds]);

  // For country/state pages, results should be filtered to only that country/state
  // until the user interacts with the map via pan/zoom (note: resizing the window triggers zoom events)
  const hasUserZoomed = React.useRef(false);
  const hasUserPanned = React.useRef(false);
  const { visibleHotels: preFilteredHotels } = useGetFilteredHotels({
    address: data?.match?.address,
    hasUserPanned: hasUserPanned.current,
    hasUserZoomed: hasUserZoomed.current,
    isLoading: isHotelSummaryOptionsLoading,
    matchName: data?.match?.name,
    type: data?.match?.type,
  });

  const visibleHotels = preFilteredHotels.filter((hotel) => {
    const currentHotel = hashedHotelSummaryOptionsHotelData?.[hotel];
    if (currentHotel)
      return applyFiltersToHotel({
        amenities: activeFiltersState.amenityFilters,
        attributes: activeFiltersState.attributeFilters,
        brandCodes: activeFiltersState.brandFilters,
        rating: activeFiltersState.ratingsFilter,
        hotel: currentHotel,
        showAvailableHotelsOnly: activeFiltersState.showAvailableHotels,
        hasMPACallResolved: true,
        useLeadPrice: true,
        priceRange: activeFiltersState.priceFilter,
        maxPoints: queryParameters?.maxPoints,
        shouldUsePoints,
      });
    return null;
  });

  const handleBoundsChange = React.useCallback(
    debounce((newBounds: google.maps.LatLngBoundsLiteral) => {
      if (JSON.stringify(mapBounds) !== JSON.stringify(newBounds)) {
        setMapBounds(newBounds);
        boundsChangeCount.current++;
      }
    }, 300),
    [mapBounds, setMapBounds]
  );

  React.useEffect(() => {
    if (!isHotelSummaryOptionsLoading) {
      allQuadNodesLoaded.current = true;
      dispatch({
        type: 'UPDATE_HOTELS_IN_BOUNDS',
        payload: {
          boundingBox: mapBounds,
          hashedHotelSummaryOptionsHotelData,
          useLeadPrice: true,
        },
      });
    }
  }, [
    mapBounds,
    isHotelSummaryOptionsLoading,
    Object.keys(hashedHotelSummaryOptionsHotelData).length,
  ]);

  const [srCounter, setSrCounter] = React.useState(1); // set when we want SR to repeat text

  const [hotelCountMessage, setHotelCountMessage] = React.useState(() =>
    totalSize > MAX_PROPERTIES_PER_LIST_VIEW_PAGE && isListVisible
      ? t('hotel-counter-text:hotelShowingWithPages', {
          count: totalSize,
          pageStart: 1,
          pageEnd: 20,
        })
      : t('hotel-counter-text:hotelShowingWithCount', { count: totalSize })
  );

  const [srHotelCountMessage, setSrHotelCountMessage] = React.useState(() =>
    visibleHotels.length
      ? t('hotel-counter-text:hotelShowingWithCount', { count: totalSize })
      : t('locations-page:notFoundMessage')
  );

  const centerCoordinate = React.useMemo(() => {
    if (data?.location?.globalBounds && coordinate) {
      return {
        lat: coordinate.latitude,
        lng: coordinate.longitude,
      };
    } else if (shouldUseParamCoords) {
      return {
        lat: queryParameters?.coords?.latitude || 0,
        lng: queryParameters?.coords?.longitude || 0,
      };
    } else {
      return {
        lat: data?.match?.geometry?.location.latitude || 0,
        lng: data?.match?.geometry?.location.longitude || 0,
      };
    }
  }, [
    data?.location?.globalBounds,
    coordinate,
    data?.match?.geometry?.location.latitude,
    data?.match?.geometry?.location.longitude,
    shouldUseParamCoords,
  ]);

  const allHotelsSorted = useSortHotels(
    sortType,
    brandCode || '',
    Object.values(hashedHotelSummaryOptionsHotelData),
    centerCoordinate
  );

  const visibleHotelsSet = new Set(visibleHotels);
  const visibleSortedHotels = allHotelsSorted.filter((hotel) =>
    visibleHotelsSet.has(hotel.ctyhocn)
  );

  const onPageChanged = React.useCallback(
    (pageIndex: number, pageSize: number) => {
      const count = !isInitialRender.current ? visibleHotels.length : totalSize;
      const pageStart = pageIndex * pageSize + 1;
      const pageEnd = Math.min(count, pageIndex * pageSize + pageSize);

      const message =
        count > pageSize && isListVisible
          ? t('hotel-counter-text:hotelShowingWithPages', {
              count,
              pageStart,
              pageEnd,
            })
          : t('hotel-counter-text:hotelShowingWithCount', { count });
      const srMessage = count === 0 ? t('locations-page:notFoundMessage') : message;
      setHotelCountMessage(message);
      setSrHotelCountMessage(srMessage);
    },
    [isGlobalPage, sortType, isInitialRender.current, totalSize, visibleHotels]
  );

  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (isHotelSummaryOptionsLoading) {
      interval = setInterval(() => setSrCounter(srCounter + 1), 3000);
    } else {
      setSrCounter(0);
    }
    return () => clearInterval(interval);
  }, [isHotelSummaryOptionsLoading, srCounter]);

  // Metadata information
  const seoSchema = getSeoSchema(
    data?.location?.breadcrumbs,
    env.OHW_BASE_URL,
    router.locale as string
  );

  const handleFilterChange = React.useCallback(
    (newActiveFiltersState: Partial<ActiveFiltersState>) => {
      //if this is a category page re-run location page query using base path w/out category param
      if (
        !isInitialRender.current &&
        data?.location?.category &&
        !isEqual(newActiveFiltersState, activeFiltersState)
      ) {
        //THIS IS A HACK FOR 3398 UNTIL WE IMPLEMENT BRAND TICKET FIX. If basepath is locations do not update the locationQueryPath as this nulls out the entire return of query.
        if (data?.location.paths?.base !== 'locations/') {
          setLocationQueryPath(data?.location.paths?.base || '');
        }
      }

      dispatch({
        type: 'APPLY_FILTERS_TO_HOTELS_IN_BOUNDS',
        payload: {
          activeFiltersState: newActiveFiltersState,
          useLeadPrice: true,
          brandCode,
        },
      });
    },
    [activeFiltersState, brandCode, data?.location?.category, data?.location.paths?.base, dispatch]
  );

  const handleFilterReset = () => {
    //if this is a category page re-run location page query using base path w/out category param
    if (!isInitialRender.current && data?.location?.category) {
      //HACK: THIS IS A HACK FOR 3398 UNTIL WE IMPLEMENT BRAND TICKET FIX. If basepath is locations do not update the locationQueryPath as this nulls out the entire return of query.
      if (data?.location.paths?.base !== 'locations/') {
        setLocationQueryPath(data?.location.paths?.base || '');
      }
    }
  };

  const pageInterlinks = data?.location.pageInterlinks;

  const listViewRef = useRef(null);
  const { width: listViewWidth } = useResizeObserver({ ref: listViewRef });

  /*NHCSEARCH-5063 Renovation chip MVT - if implemented this hook can be called 
    directly in map and hotelCard to avoid prop drilling, 
  but for MVT, it needs single source of truth for provisional bool */
  const renovatedCtyhocns = useRenovationChipMVT(visibleHotels);

  //NHCSEARCH-5123 Bigger Map CTA
  const { isMVTVariant: isBiggerMapCTAVariant } = useGetMVTSelections({
    agentId: BIGGER_MAP_CTA.agentId,
    isProvisional: useMediaQuery('(max-width: 767px)'),
    MVTVariants: ['a', 'b', 'c'],
  });
  const isBiggerCTAVariant = isBiggerMapCTAVariant.b || isBiggerMapCTAVariant.c;

  return (
    <>
      <MetaData
        brandCode={brandCode || 'WW'}
        description={data?.location?.meta?.description || ''}
        pageTitle={data?.location?.meta?.pageTitle || ''}
        path={router.asPath.replace(/^\/[a-z]{2}\//i, '/').replace(/\/$/, '')}
        seoSchema={seoSchema}
        isGlobalBounds={!!data?.location?.globalBounds}
        locationPageMatchType={data?.match?.type}
      />
      <SearchHeader brandCode={brandCode} brandName={brandName} isFluid />
      <main role="main">
        <div className="container-fluid mb-4">
          <div className="flex flex-col">
            <div className="mt-4 flex flex-col">
              <div className="mt-2 flex-initial md:mt-0">
                <Breadcrumbs breadcrumbs={data?.location?.breadcrumbs || []} />
              </div>
            </div>

            <div className="mb-2 mt-4 md:mb-0">
              <PlacesSearch
                brandCode={brandCode || ''}
                ctaText={t('locations-page:update')}
                hasShallowRoutingEnabled={true}
                matchId={data?.match?.id}
              />
            </div>
          </div>
          <div className="border-secondary mb-4 flex flex-wrap items-center border-b-4 pb-4">
            <div className="flex flex-wrap items-end lg:items-center">
              <SortByDropDown />
              <CurrencyConverter />
            </div>
            <UsePoints />
            <CompareHotelsToggle />
            <Filters
              amenities={data?.hotelSummaryOptions?.amenities}
              amenityCategories={data?.hotelSummaryOptions?.amenityCategories}
              brands={data?.hotelSummaryOptions?.brands}
              filteredHotelCount={visibleHotels.length}
              onFilterChange={handleFilterChange}
              onResetFilters={() => handleFilterReset()}
              showSaleFilter={false}
            />
          </div>
          <div className="relative flex">
            <div
              ref={listViewRef}
              style={{ minHeight: '100vh' }}
              className={cx(
                'z-1 bg-bg relative h-auto md:static max-md:w-full md:max-w-[440px] lg:max-w-[694px] xl:max-w-[888px] lg:w-[694px] xl:w-[888px]',
                {
                  'max-sm:z-0 hidden': !isListVisible,
                }
              )}
            >
              <LocationsPageHeadingLevel1 title={data?.location?.title} />
              <SuggestedFilterButtons
                amenities={data?.hotelSummaryOptions?.amenities}
                isConductricsLoaded={isConductricsLoaded}
                onFilterCriteriaChange={(activeFiltersState: ActiveFiltersState) =>
                  handleFilterChange(activeFiltersState)
                }
                visibleHotels={visibleHotels}
                matchId={data?.match?.id}
              />
              <LogInBanner />
              <div
                className={cx('flex', {
                  'flex-col items-start pb-3': isBiggerCTAVariant,
                  'items-center pb-2 justify-between': !isBiggerCTAVariant,
                })}
              >
                <div className={cx({ 'pb-2.5': isBiggerCTAVariant })}>
                  <HotelCounter
                    count={visibleSortedHotels.length}
                    currentPageIndex={paginationIndex}
                    customMessage={hotelCountMessage}
                    customSRMessage={srHotelCountMessage}
                    isListVisible={isListVisible}
                    isLoading={isHotelSummaryOptionsLoading || isLocationPageHSOLoading}
                  />
                </div>
                {/* Map/List toggle button */}
                <MapListToggleButton />
              </div>

              <div className="md:sticky md:top-0">
                <ListView
                  brandCode={brandCode || ''}
                  brandName={brandName}
                  centerCoordinate={centerCoordinate}
                  isConductricsLoaded={true}
                  onPageChanged={onPageChanged}
                  sortedHotels={visibleSortedHotels}
                  isHSODataLoading={isHotelSummaryOptionsLoading || isLocationPageHSOLoading}
                  // NHCSEARCH-5063 Reno badges
                  renoHotels={renovatedCtyhocns}
                />
                <AboutLocationsPage
                  about={data?.location?.about}
                  aboutClassName="px-0 py-3"
                  contentBlockClassName="py-1"
                  descriptionClassName="px-0 pb-1 text-xs"
                />
                {pageInterlinks &&
                  pageInterlinks?.length > 0 &&
                  pageInterlinks?.map(
                    (linkItem: LocationPageInterlink, index: React.Key | null | undefined) => (
                      <Interlinks
                        index={`${index}`}
                        interlinkTitle={linkItem?.title || ''}
                        interlinks={linkItem?.links}
                        key={linkItem?.title || `title-${linkItem.links[0]?.name ?? index}`}
                      />
                    )
                  )}
                <div>
                  <p className="p-8 text-xs" data-testid="rateLegalDisclosure">
                    {shouldUsePoints
                      ? t('locations-page:pointsLegalDisclaimer')
                      : t('locations-page:legalDisclaimer')}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={
                !isSlimView && listViewWidth
                  ? { width: `calc(100% - ${listViewWidth}px)` }
                  : undefined
              }
              className={cx(
                '@container absolute z-0 h-screen md:sticky md:top-0 md:ml-4 rtl:md:mr-4 ',
                {
                  'w-full': isSlimView,
                  'max-sm:z-1 relative': !isListVisible,
                  'max-md:invisible': isListVisible,
                  'w-4/7': !listViewWidth,
                }
              )}
              data-testid="mapContainer"
            >
              {/* Map/List toggle button */}
              {!isListVisible && (
                <div
                  className={cx('flex', {
                    'flex-col items-start pb-3': isBiggerCTAVariant,
                    'items-center pb-2 justify-between': !isBiggerCTAVariant,
                  })}
                >
                  {/* NHCSEARCH-5123 - Bigger map CTA -
                    Consider extracting this block above map/list view into its own comp if implemented */}
                  {isBiggerMapCTAVariant.c ? (
                    <div className="flex flex-col items-start">
                      <LocationsPageHeadingLevel1 title={data?.location?.title} />
                      <SuggestedFilterButtons
                        amenities={data?.hotelSummaryOptions?.amenities}
                        isConductricsLoaded={isConductricsLoaded}
                        onFilterCriteriaChange={(activeFiltersState: ActiveFiltersState) =>
                          handleFilterChange(activeFiltersState)
                        }
                        visibleHotels={visibleHotels}
                        matchId={data?.match?.id}
                      />
                    </div>
                  ) : null}
                  <div className={cx('', { 'pb-2.5': isBiggerCTAVariant })}>
                    <HotelCounter
                      count={visibleSortedHotels.length}
                      currentPageIndex={paginationIndex}
                      customMessage={hotelCountMessage}
                      customSRMessage={srHotelCountMessage}
                      isListVisible={isListVisible}
                      isLoading={isHotelSummaryOptionsLoading}
                    />
                  </div>
                  <MapListToggleButton />
                </div>
              )}

              {isHotelSummaryOptionsLoading && (
                <div className="z-1 bg-bg absolute ml-4 mt-4 p-2">
                  <Spinner className="text-secondary" size="lg" />
                </div>
              )}
              {/* Show zoom in overlay on map if the MPA fetch limiter is enabled and we have > 150 hotels on map with static markers */}
              {!!(visibleSortedHotels.length > MAX_PRICE_PROPERTY_LIMIT) && (
                <div className="flex justify-center">
                  <ZoomInForPricing />
                </div>
              )}
              <Map
                allQuadNodesLoaded={allQuadNodesLoaded}
                handleBoundsChange={handleBoundsChange}
                hasUserPanned={hasUserPanned}
                hasUserZoomed={hasUserZoomed}
                initialBounds={initialBounds || initBounds}
              >
                <LocationsMapMarkers
                  centerCoordinate={centerCoordinate}
                  hotelsInBounds={hotelsInBounds}
                  visibleHotels={visibleHotels}
                  // NHCSEARCH-5063 Reno badges
                  renoHotels={renovatedCtyhocns}
                />
              </Map>
            </div>
          </div>
        </div>
      </main>
      <div className={cx({ 'mt-10 md:mt-0': !isListVisible })}>
        <Footer isFluid />
      </div>
      <CompareBar centerCoordinate={centerCoordinate} />
      {/* Hotel details Panel */}
      {!!selectedCtyhocn ? <HotelDetails /> : null}
    </>
  );
};
